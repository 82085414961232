/*
 * @Author: your name
 * @Date: 2020-12-15 17:17:40
 * @LastEditTime: 2021-02-07 17:20:44
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\channelOrderTyping\index.js
 */
import Step from "@/components/global/Step/Step.vue";
// import NumberBox from "@/components/global/NumberBox/NumberBox.vue";

export default {
  name: "index",
  components: {
    Step,
    // NumberBox
  },
  data() {
    return {
      steps: ["选择商品", "确认订单信息", "提交订单"],
      step: 1, //进度条
      pageLoadFlag: false,
      dataList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "订单录入"
        },
        {
          path: "/shoppingCart",
          name: "shoppingCart",
          title: "渠道订单录入"
        }
      ]
    };
  },
  mounted() {},
  methods: {}
};
